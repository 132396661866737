import React, { useRef } from 'react'
import { useSelector } from 'react-redux'

import styled from 'styled-components'
import Popup from 'reactjs-popup'

import {
  InputBox,
  Modal,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
  Actions,
  CancelBtn,
  SubmitBtnModal,
  ModalLabel,
  InputField,
} from './CommonStylesForms'

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`

function FormDTCIssuance(props) {
  const schemas = useSelector((state) => state.settings.schemas)
  const contactSelected = useSelector((state) => state.contacts.contactSelected)

  const dtcForm = useRef(null)

  function closeModal() {
    props.closeDTCModal()
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(dtcForm.current)

    let attributes = {}
    if (contactSelected) {
      attributes = [
        {
          name: 'dtc',
          value: form.get('dtc') || '',
        },
        {
          name: 'viz',
          value: form.get('viz') || '',
        },
      ]
    }

    const schema = schemas.SCHEMA_DTC_TYPE1_IDENTITY
    const schemaParts = schema.split(':')

    contactSelected.Connections.forEach((connection) => {
      const newCredential = {
        connectionID: connection.connection_id,
        schemaID: schema,
        schemaVersion: schemaParts[3],
        schemaName: schemaParts[2],
        schemaIssuerDID: schemaParts[0],
        comment: '',
        attributes: attributes,
      }

      props.submitDTCCredential(newCredential, e)
    })

    closeModal()
  }

  return (
    <StyledPopup
      open={props.dtcModalIsOpen}
      closeOnDocumentClick
      onClose={closeModal}
    >
      <Modal className="modal">
        <ModalHeader>Issue DTC Credential</ModalHeader>
        <ModalContentWrapper>
          <ModalContent>
            <form id="form" onSubmit={handleSubmit} ref={dtcForm}>
              <InputBox>
                <ModalLabel htmlFor="dtc">Base64 DTC</ModalLabel>
                <InputField type="text" name="dtc"></InputField>
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="viz">Base64 VIZ</ModalLabel>
                <InputField type="text" name="viz"></InputField>
              </InputBox>
              <Actions>
                <CancelBtn type="button" onClick={closeModal}>
                  Cancel
                </CancelBtn>
                <SubmitBtnModal type="submit">Submit</SubmitBtnModal>
              </Actions>
            </form>
          </ModalContent>
        </ModalContentWrapper>
        <CloseBtn onClick={closeModal}>&times;</CloseBtn>
      </Modal>
    </StyledPopup>
  )
}

export default FormDTCIssuance
